export function verifyUser(user: {email: string}): Promise<UserValidationResult> {
    return fetch('/api/user/verify', {
        method: 'POST',
        body: JSON.stringify(user)
    }).then(resp => resp.json());
}

export function getUserInfo(email: string): Promise<any> {
    return fetch('/api/user/info', {
        method: 'POST',
        body: JSON.stringify({email})
    }).then(resp => resp.json());
}

export class UserValidationResult {
    success: boolean = true;
    email?: string;
    constructor(errors? : { [key: string]: string }) {
        if(!errors) return;
        this.success = false;
        Object.assign(this, errors);
    }
}